(function () {
  "use strict";
  const el = document.querySelector('[data-bs-toggle="offcanvas"]');

  if (el) {
    el.addEventListener("click", function () {
      document.querySelector(".offcanvas-collapse").classList.toggle("open");
    });
  }
})();

 
